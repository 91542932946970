import React, { FC, useCallback, useEffect, useState } from 'react';

import AnonymousUserPayment from './anonymous/AnonymousUserPayment';
import LoggedInUserPayment from './loggedInUser/LoggedInUserPayment';
import { useFormContext } from 'react-hook-form';
import {
  BookRideFormFields,
  BusinessAccountType,
  BusinessTravelProgram,
  PaymentMethodResponseByKaptyn,
  ProgramPaymentMethods,
  RiderResponse,
} from '../../../types';

import CardStepItem from '../CardStepItem';
import { Box, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../../theme/typography';
import { useAuthContext } from '../../../../../auth/useAuthContext';
import { UserType } from 'shared.types';
import { ENDPOINTS } from 'services/Endpoints';
import { ServiceTypes, useBaseService } from 'services/useBaseService';

interface Props {
  number: number;
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton: boolean;
}

const shouldDisplayEditButton = (
  user: UserType | null,
  account: BusinessAccountType | null | undefined,
  program: BusinessTravelProgram | null | undefined,
  rider: Partial<RiderResponse> | undefined,
): boolean => {
  if (!user && rider != null) {
    return true;
  }

  if (user != null && account != null && program != null) {
    return true;
  }

  if (user != null && account == undefined) {
    return true;
  }

  return false;
};

const Payment: FC<Props> = ({
  number,
  onContinue,
  onEdit,
  isEditing,
  displayEditButton,
}) => {
  const { get } = useBaseService({ type: ServiceTypes.PaymentAPI });
  const [loggedInUserPaymentMethods, setLoggedInUserPaymentMethods] =
    useState<PaymentMethodResponseByKaptyn[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { watch, setValue, getValues } = useFormContext<BookRideFormFields>();
  const [
    payment,
    businessProgram,
    programPaymentMethod,
    businessTravelAccount,
  ] = watch([
    'PAYMENT',
    'BUSINESS_TRAVEL_PROGRAM',
    'PROGRAM_PAYMENT_METHOD',
    'BUSINESS_TRAVEL_ACCOUNT',
  ]);
  const { user } = useAuthContext();

  const onInternalEdit = async (): Promise<void> => {
    if (!user) {
      setValue('PAYMENT', undefined);
    }
    onEdit();
  };

  const getLoggedInUserPaymentMethods = useCallback(async () => {
    setIsLoading(true);
    try {
      const userPaymentMethods = await get<PaymentMethodResponseByKaptyn[]>(
        ENDPOINTS.GetLoggedInUserPaymentMethods,
        true,
      );
      setLoggedInUserPaymentMethods(userPaymentMethods);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [get]);

  useEffect(() => {
    if (user && !loggedInUserPaymentMethods) {
      getLoggedInUserPaymentMethods();
    }
  }, [getLoggedInUserPaymentMethods, loggedInUserPaymentMethods, user]);

  return (
    <>
      <CardStepItem
        title={`${number}. Payment`}
        onContinue={onContinue}
        onEdit={onInternalEdit}
        isEditing={isEditing}
        displayConfirmButton={false}
        displayEditButton={
          displayEditButton &&
          shouldDisplayEditButton(
            user,
            businessTravelAccount,
            businessProgram,
            getValues('RIDER'),
          )
        }
        containerDataCy="pay-with"
        buttonDataCy="paymentBtn"
        editButtonDataCy="payment-edit-btn"
        titleDataCy='payment-title'
      >
        {!isEditing && (
          <>
            {payment && (
              <>
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    mt: '5px!important',
                  }}
                >
                  ****{payment?.payment_method_by_kaptyn?.number}-
                  {payment?.payment_method_by_kaptyn?.brand}
                </Typography>
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    mt: '5px!important',
                  }}
                >
                  {payment?.payment_method_by_kaptyn?.expMonth}/
                  {payment?.payment_method_by_kaptyn?.expYear}
                </Typography>
              </>
            )}

            {programPaymentMethod === ProgramPaymentMethods.DirectlyBilled && (
              <Typography
                sx={{
                  ...TYPOGRAPHY.paragraph,
                  mt: '5px!important',
                }}
              >
                Billed to organization
              </Typography>
            )}
          </>
        )}
        {isEditing && (
          <>
            {!user && <AnonymousUserPayment onContinue={onContinue} />}
            {!businessProgram && user && (
              <LoggedInUserPayment
                onContinue={onContinue}
                isLoading={isLoading}
                loggedInUserPaymentMethods={loggedInUserPaymentMethods}
                setIsLoading={setIsLoading}
                setLoggedInUserPaymentMethods={setLoggedInUserPaymentMethods}
              />
            )}

            {businessProgram?.passengerPays && (
              <Box
                sx={{
                  border: '2px solid',
                  borderColor:
                    programPaymentMethod === ProgramPaymentMethods.PassengerPays
                      ? 'black'
                      : '#d3d3d3',
                  borderRadius: '10px',
                  padding: '10px',
                }}
                onClick={(): void =>
                  setValue(
                    'PROGRAM_PAYMENT_METHOD',
                    ProgramPaymentMethods.PassengerPays,
                  )
                }
                data-cy="passenger-pays-box"
              >
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    mt: '5px!important',
                  }}
                >
                  Personal
                </Typography>
                <LoggedInUserPayment
                  onContinue={onContinue}
                  isLoading={isLoading}
                  loggedInUserPaymentMethods={loggedInUserPaymentMethods}
                  setIsLoading={setIsLoading}
                  setLoggedInUserPaymentMethods={setLoggedInUserPaymentMethods}
                />
              </Box>
            )}
            {businessProgram?.directlyBilledOrganization && (
              <Box
                sx={{
                  border: '2px solid',
                  borderColor:
                    programPaymentMethod ===
                    ProgramPaymentMethods.DirectlyBilled
                      ? 'black'
                      : '#d3d3d3',
                  borderRadius: '10px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
                onClick={(): void => {
                  setValue(
                    'PROGRAM_PAYMENT_METHOD',
                    ProgramPaymentMethods.DirectlyBilled,
                  );
                  setValue('PAYMENT', undefined);
                  onContinue();
                }}
                data-cy="directly-billed-box"
              >
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    mt: '5px!important',
                  }}
                >
                  Billed to organization
                </Typography>
              </Box>
            )}
          </>
        )}
      </CardStepItem>
    </>
  );
};

export default Payment;
