import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuthContext } from '../../auth/useAuthContext';
import { ServiceTypes, useBaseService } from '../../services/useBaseService';
import { ENDPOINTS } from '../../services/Endpoints';
import { Box, Divider, Stack } from '@mui/material';
import SvgIcon from './SvgIcon';
import { useFormContext } from 'react-hook-form';
import {
  BookRideFieldNames,
  BusinessAccountType,
} from '../../pages/bookRide/types';
import { COLORS } from '../../theme/pallete';
import { TYPOGRAPHY } from '../../theme/typography';
import { LoadingComponent } from './LoadingComponent';
import { CALL_SUPPORT_PHONE_NUMBER, LOCAL_STORAGE_KEYS } from 'shared.config';
import { getNameInitials } from 'utils/string';

export default function LoggedInUserMenu({
  isPass,
}: {
  isPass: boolean;
}): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [accounts, setAccounts] = useState<BusinessAccountType[]>([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState<boolean>(false);

  const { user, logout } = useAuthContext();

  const { get } = useBaseService({ type: ServiceTypes.AccountAPI });

  const { watch, setValue } = useFormContext();

  const [businessTravelAccount] = watch([
    BookRideFieldNames.BUSINESS_TRAVEL_ACCOUNT,
  ]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const setBusinessTravelAccount = useCallback(
    (account: BusinessAccountType | null): void => {
      setValue(BookRideFieldNames.BUSINESS_TRAVEL_ACCOUNT, account);
      if (account?.id)
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.LAST_ACCOUNT_ID_USED,
          account.id,
        );
      else localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_ACCOUNT_ID_USED);
    },
    [setValue],
  );

  const getAccounts = useCallback(async (): Promise<void> => {
    setIsLoadingAccounts(true);
    try {
      const accounts = await get<BusinessAccountType[]>(
        ENDPOINTS.GetAllBusinessAccountFromCurrentUser,
        true,
      );

      accounts.filter((account): void => {
        user?.rider_in_accounts.includes(account.id ?? '');
      });

      setAccounts(accounts);

      const lastUsedAccountId = localStorage.getItem(
        LOCAL_STORAGE_KEYS.LAST_ACCOUNT_ID_USED,
      );
      const lastLoggedInUserId = localStorage.getItem(
        LOCAL_STORAGE_KEYS.LAST_LOGGED_IN_USER_ID,
      );
      if (lastUsedAccountId && lastLoggedInUserId === user?.user_id) {
        const lastUsedAccountObj = accounts.find(
          (account) => account.id === lastUsedAccountId,
        );
        if (lastUsedAccountObj && !isPass) {
          setBusinessTravelAccount(lastUsedAccountObj);
        }
      }

      setIsLoadingAccounts(false);
    } catch (error) {
      console.error(error);
      setIsLoadingAccounts(false);
    }
  }, [
    get,
    setBusinessTravelAccount,
    user?.rider_in_accounts,
    user?.user_id,
    isPass,
  ]);

  useEffect(() => {
    if (user) {
      getAccounts();
    }
  }, [user, getAccounts]);

  const avatar = (dataCy: string): ReactElement => (
    <Box
      data-cy={dataCy}
      sx={{
        backgroundColor: COLORS.PURPLE_MEDIUM_1,
        width: '40px',
        minWidth: '40px',
        height: '40px',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
      }}
    >
      {user && getNameInitials(user)}
    </Box>
  );

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          height: '40px',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          paddingRight: '0',
          marginLeft: 'auto',
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        {businessTravelAccount && (
          <Box
            sx={{
              backgroundColor: '#F0F1F3',
              padding: '3px 13px',
              borderRadius: '8px',
              display: 'inline-flex',
              marginRight: '15px',
            }}
          >
            <Typography
              sx={{
                color: 'black',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                fontWeight: '600',
                'text-transform': 'capitalize',
              }}
            >
              {businessTravelAccount?.name}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            position: 'relative',
          }}
        >
          {avatar('user-avatar')}
          {businessTravelAccount && (
            <Box
              sx={{
                backgroundColor: 'white',
                position: 'absolute',
                right: '-5px',
                bottom: '-5px',
                width: '22px',
                height: '22px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
              }}
            >
              <SvgIcon
                src="/assets/icons/icon_suitCase.svg"
                color="black"
                width="18px"
                height="18px"
              />
            </Box>
          )}
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          padding: '0',
          '.MuiPaper-root': {
            overflow: 'visible',
            borderRadius: '10px',
          },
        }}
      >
        <Box
          sx={{
            maxWidth: '268px',
            width: '268px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              padding: '20px 5px 20px 24px ',
            }}
          >
            {avatar('inner-user-avatar')}
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                marginLeft: '16px',
              }}
            >
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  ...TYPOGRAPHY.subtitle2,
                }}
              >
                {user?.first_name}&nbsp;
                {user?.last_name}
              </Typography>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  ...TYPOGRAPHY.subtitle2,
                }}
              >
                {user?.email}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          {user?.rider_in_accounts && (
            <Box
              data-cy="rider-profiles"
              sx={{
                padding: '10px 24px 0',
              }}
            >
              <Typography
                sx={{
                  ...TYPOGRAPHY.tinyText,
                }}
              >
                Ride profiles
              </Typography>

              <Typography
                sx={{
                  my: '20px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  ...TYPOGRAPHY.paragraph,
                }}
                onClick={(): void => {
                  setBusinessTravelAccount(null);
                  setValue(
                    BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM,
                    undefined,
                  );
                  handleClose();
                }}
              >
                Personal
                {!businessTravelAccount && (
                  <SvgIcon
                    width="20px"
                    src="/assets/icons/icon_greenCheckMark.svg"
                    color="black"
                  />
                )}
              </Typography>

              {!isPass && isLoadingAccounts && (
                <>
                  <Typography
                    sx={{
                      my: '20px',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      ...TYPOGRAPHY.paragraph,
                    }}
                  >
                    Loading your accounts...
                  </Typography>
                  <LoadingComponent verticalMargin={12} />
                </>
              )}

              {!isPass &&
                !isLoadingAccounts &&
                accounts
                  ?.map((account) => {
                    return (
                      <Typography
                        key={account.id}
                        sx={{
                          my: '20px',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          ...TYPOGRAPHY.paragraph,
                        }}
                        onClick={(): void => {
                          setBusinessTravelAccount(account);
                          handleClose();
                        }}
                      >
                        {account.name}
                        {businessTravelAccount?.id === account?.id && (
                          <SvgIcon
                            width="20px"
                            src="/assets/icons/icon_greenCheckMark.svg"
                            color="black"
                          />
                        )}
                      </Typography>
                    );
                  })
                  .slice(0, 5)}

              {!isPass && accounts.length > 5 && (
                <Typography
                  sx={{
                    my: '20px',
                    cursor: 'pointer',
                    alignItems: 'center',

                    position: 'relative',
                    '&:hover #subMenu': {
                      display: 'block',
                    },
                    ...TYPOGRAPHY.paragraph,
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    View more profiles
                    <SvgIcon
                      src="/assets/icons/icon_arrowRight.svg"
                      width="19px"
                    />
                  </Stack>

                  <Box
                    id="subMenu"
                    sx={{
                      position: {
                        xs: 'static',
                        md: 'absolute',
                      },
                      left: {
                        xs: 'unset',
                        md: '-276px',
                      },
                      top: {
                        xs: 'unset',
                        md: '0',
                      },
                      zIndex: 1,
                      display: 'none',
                      paddingRight: '30px',
                    }}
                  >
                    <Box
                      sx={{
                        background: 'white',
                        height: '247px',
                        width: '252px',
                        borderRadius: '10px',
                        overflowY: 'auto',
                        padding: {
                          xs: '0',
                          md: '5px 22px',
                        },

                        'box-shadow': {
                          xs: 'none',
                          md: '1px 1px 5px 0px lightGrey',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          display: {
                            xs: 'none',
                            md: 'block',
                          },
                          ...TYPOGRAPHY.tinyText,
                        }}
                      >
                        Ride profiles
                      </Typography>
                      {accounts
                        ?.map((account) => {
                          return (
                            <Typography
                              key={account.id}
                              sx={{
                                my: '20px',
                                cursor: 'pointer',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                ...TYPOGRAPHY.paragraph,
                              }}
                              onClick={(): void => {
                                setBusinessTravelAccount(account);
                                handleClose();
                              }}
                            >
                              {account.name}
                              {businessTravelAccount?.id === account?.id && (
                                <SvgIcon
                                  width="20px"
                                  src="/assets/icons/icon_greenCheckMark.svg"
                                  color="black"
                                />
                              )}
                            </Typography>
                          );
                        })
                        .slice(5)}
                    </Box>
                  </Box>
                </Typography>
              )}
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                }}
              >
                If you need to book a ride with special conditions please call
                {CALL_SUPPORT_PHONE_NUMBER} to connect with your Ride Concierge
              </Typography>
            </Box>
          )}
          <Typography
            sx={{
              my: '20px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              px: '24px',
              ...TYPOGRAPHY.paragraph,
            }}
            onClick={logout}
          >
            <SvgIcon
              src="/assets/icons/icon_doorArrow.svg"
              sx={{
                mr: '10px',
              }}
              width="20px"
            />
            Sign Out
          </Typography>
        </Box>
      </Popover>
    </div>
  );
}
