import { Box, MenuItem } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';

import { BookRideFieldNames } from '../../types';

import PlacesAutocomplete from '../../../../components/shared/formFieldRelated/PlacesAutocomplete';
import AppButton from '../../../../components/shared/AppButton';
import AppDatePicker from '../../../../components/shared/formFieldRelated/AppDatePicker/AppDatePicker';
import { CustomTimePicker } from '../../../../components/shared/formFieldRelated/CustomTimePicker';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';

import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { RHFSelect } from 'components/shared/formFieldRelated/RHFSelect';
import { TYPOGRAPHY } from 'theme/typography';
import { COLORS } from 'theme/pallete';
import { AddressTypes, LocationDetailsType } from 'shared.types';
import dayjs from 'dayjs';
import { convertLocationTypes } from 'utils/locations';
import { TIME_12HRS_FORMAT } from 'constans/DateRelatedContants';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const regularCellsStyles = {
  flex: {
    xs: 'none',
    md: '300px 1 1',
  },
};

const OneWayForm = ({
  isGettingServiceProvider,
}: {
  isGettingServiceProvider: boolean;
}): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minPickupTime, setMinPickupTime] = useState<string>();
  const [maxPickupTime, setMaxPickupTime] = useState<string>();
  const { programPass } = useProgramPassContext();

  const { trigger, watch, setValue } = useFormContext();

  const selectedPickupDate = watch(BookRideFieldNames.PICKUP_DATE);

  useEffect(() => {
    if (programPass) {
      const rideValidFrom = dayjs(
        programPass.businessProgram.passRideValidFrom,
      );
      const rideValidTo = dayjs(programPass.businessProgram.passRideValidTo);

      if (
        selectedPickupDate === rideValidFrom.format('YYYY-MM-DD') &&
        minPickupTime !== rideValidFrom.format(TIME_12HRS_FORMAT)
      ) {
        setMinPickupTime(rideValidFrom.format(TIME_12HRS_FORMAT));
      } else if (
        minPickupTime !== undefined &&
        selectedPickupDate !== rideValidFrom.format('YYYY-MM-DD')
      ) {
        setMinPickupTime(undefined);
      }

      if (
        selectedPickupDate === rideValidTo.format('YYYY-MM-DD') &&
        maxPickupTime !== rideValidTo.format(TIME_12HRS_FORMAT)
      ) {
        setMaxPickupTime(rideValidTo.format(TIME_12HRS_FORMAT));
      } else if (
        maxPickupTime !== undefined &&
        selectedPickupDate !== rideValidTo.format('YYYY-MM-DD')
      ) {
        setMaxPickupTime(undefined);
      }
    }
  }, [maxPickupTime, minPickupTime, programPass, watch, selectedPickupDate]);

  const next = async (): Promise<void> => {
    setIsSubmitting(true);
    const targetFields = [
      BookRideFieldNames.PICKUP_ADDRESS,
      BookRideFieldNames.DROPOFF_ADDRESS,
      BookRideFieldNames.PICKUP_DATE,
      BookRideFieldNames.PICKUP_TIME,
    ];
    const isValid = await trigger(targetFields);

    if (isValid) {
      const step2PassUrl = `/pass/${programPass?.id}/step2` + location.search;
      const step2NormalUrl = `/book-ride/step2` + location.search;

      if (programPass) {
        navigate(step2PassUrl);
      } else {
        navigate(step2NormalUrl);
      }
    }
    setIsSubmitting(false);
  };

  const allowedPickUpAddresses =
    programPass &&
    programPass.businessProgram.allowedAddresses &&
    programPass.businessProgram.allowedAddresses.filter(
      (address) => address.allowPickUp,
    );
  const mappedAllowedPickUpAddresses =
    allowedPickUpAddresses &&
    convertLocationTypes(allowedPickUpAddresses, AddressTypes.PickUp);

  const allowedDropOffAddresses =
    programPass &&
    programPass.businessProgram.allowedAddresses &&
    programPass.businessProgram.allowedAddresses.filter(
      (address) => address.allowDropOff,
    );
  const mappedAllowedDropOffAddresses =
    allowedDropOffAddresses &&
    convertLocationTypes(allowedDropOffAddresses, AddressTypes.DropOff);

  const rideValidFrom =
    programPass && dayjs(programPass?.businessProgram.passRideValidFrom);
  const rideValidTo =
    programPass && dayjs(programPass?.businessProgram.passRideValidTo);

  const selectedPickUpAddress: LocationDetailsType = watch(
    BookRideFieldNames.PICKUP_ADDRESS,
  );

  let referenceEqualityPickUpAddress: LocationDetailsType | undefined;
  if (selectedPickUpAddress)
    referenceEqualityPickUpAddress = mappedAllowedPickUpAddresses?.find(
      (address) =>
        address.googlePlacesId === selectedPickUpAddress.googlePlacesId,
    );

  const selectedDropOffAddress: LocationDetailsType = watch(
    BookRideFieldNames.DROPOFF_ADDRESS,
  );
  let referenceEqualityDropOffAddress: LocationDetailsType | undefined;
  if (selectedDropOffAddress)
    referenceEqualityDropOffAddress = mappedAllowedDropOffAddresses?.find(
      (address) =>
        address.googlePlacesId === selectedDropOffAddress.googlePlacesId,
    );

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'stretch',
          gap: '8px',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <FormFieldLayout
          name={BookRideFieldNames.PICKUP_ADDRESS}
          label="Pickup location"
          sx={{
            ...regularCellsStyles,
            overflow:
              mappedAllowedPickUpAddresses &&
                mappedAllowedPickUpAddresses.length > 0
                ? 'hidden'
                : 'visible',
          }}
          dataCy="one-way-pickup-location"
        >
          {mappedAllowedPickUpAddresses &&
            mappedAllowedPickUpAddresses.length > 0 && (
              <RHFSelect
                name={BookRideFieldNames.PICKUP_ADDRESS}
                sx={{
                  '.MuiSelect-select': {
                    height: '48px!important',
                    backgroundColor: COLORS.GREY_MEDIUM_2,
                    borderRadius: '10px',
                    ...TYPOGRAPHY.formField,
                  },
                }}
                value={referenceEqualityPickUpAddress ?? ''}
                onChange={(e): void => {
                  setValue(BookRideFieldNames.PICKUP_ADDRESS, e.target.value);
                }}
                rendervalue={(obj: unknown): string => {
                  return (
                    (obj as { address: string })?.address ?? (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Select
                      </div>
                    )
                  );
                }}
                dataCy="pickup-address-select"
              >
                {mappedAllowedPickUpAddresses.map((address, i) => (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  <MenuItem key={i} value={address as any}>
                    {address.address}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
          {(!mappedAllowedPickUpAddresses ||
            mappedAllowedPickUpAddresses.length === 0) && (
              <PlacesAutocomplete
                name={BookRideFieldNames.PICKUP_ADDRESS}
                placeholder="Pickup location"
                addressType={AddressTypes.PickUp}
                dataCy="pickup-address-autocomplete"
              />
            )}
        </FormFieldLayout>

        <FormFieldLayout
          name={BookRideFieldNames.DROPOFF_ADDRESS}
          label="Dropoff location"
          sx={{
            ...regularCellsStyles,
            overflow:
              mappedAllowedDropOffAddresses &&
                mappedAllowedDropOffAddresses.length > 0
                ? 'hidden'
                : 'visible',
          }}
          dataCy="one-way-dropoff-location"
        >
          {mappedAllowedDropOffAddresses &&
            mappedAllowedDropOffAddresses.length > 0 && (
              <RHFSelect
                dataCy="dropoff-address-select"
                name={BookRideFieldNames.DROPOFF_ADDRESS}
                sx={{
                  '.MuiSelect-select': {
                    height: '48px!important',
                    backgroundColor: COLORS.GREY_MEDIUM_2,
                    borderRadius: '10px',
                    ...TYPOGRAPHY.formField,
                  },
                }}
                value={referenceEqualityDropOffAddress ?? ''}
                onChange={(e): void => {
                  setValue(BookRideFieldNames.DROPOFF_ADDRESS, e.target.value);
                }}
                rendervalue={(obj: unknown): string => {
                  return (
                    (obj as { address: string })?.address ?? (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Select
                      </div>
                    )
                  );
                }}
              >
                {mappedAllowedDropOffAddresses.map((address, i) => (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  <MenuItem key={i} value={address as any}>
                    {address.address}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
          {(!mappedAllowedDropOffAddresses ||
            mappedAllowedDropOffAddresses.length === 0) && (
              <PlacesAutocomplete
                name={BookRideFieldNames.DROPOFF_ADDRESS}
                placeholder="Dropoff location"
                addressType={AddressTypes.DropOff}
                dataCy="dropoff-address-autocomplete"
                locationBias={{
                  lat: selectedPickUpAddress?.latitude,
                  lng: selectedPickUpAddress?.longitude,
                }}
              />
            )}
        </FormFieldLayout>

        <FormFieldLayout
          name={BookRideFieldNames.PICKUP_DATE}
          label="Pickup date"
          sx={regularCellsStyles}
          dataCy="one-way-pickup-date"
        >
          <AppDatePicker
            name={BookRideFieldNames.PICKUP_DATE}
            minDate={rideValidFrom}
            maxDate={rideValidTo}
          />
        </FormFieldLayout>

        <FormFieldLayout
          name={BookRideFieldNames.PICKUP_TIME}
          label="Pickup time"
          sx={regularCellsStyles}
          dataCy="one-way-pickup-time"
        >
          <CustomTimePicker
            dataCy="one-way-pickup-time-picker"
            name={BookRideFieldNames.PICKUP_TIME}
            minTime={minPickupTime}
            maxTime={maxPickupTime}
          />
        </FormFieldLayout>

        <Box
          sx={{
            ...regularCellsStyles,
            flex: 'auto 0 1',
            mt: '21px',
          }}
        >
          <AppButton
            title={
              isSubmitting || isGettingServiceProvider
                ? 'Searching...'
                : 'Show rides'
            }
            additionalSx={{
              width: '120px',
              borderRadius: '20px',
              mt: '2px',
            }}
            type="button"
            onClick={next}
            data-cy="showRidesBtn"
            disabled={isSubmitting || isGettingServiceProvider}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OneWayForm;
