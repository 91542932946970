import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SxProps, Tab } from '@mui/material';
import { FONT_FAMILY } from '../../theme/typography';
import { COLORS } from '../../theme/pallete';

interface Props {
  value: string;
  tabs: { label: string; value: string; dataCy?: string }[];
  content: React.ReactNode[];
  onChange: (event: React.SyntheticEvent, position: string) => void;
  tabsWidth?: string;
  tabListSx?: SxProps;
}

const TabSystem: React.FC<Props> = ({
  value,
  tabs,
  content,
  onChange,
  tabsWidth = '360px',
  tabListSx,
}) => (
  <TabContext value={value}>
    <TabList
      variant="fullWidth"
      sx={{
        display: 'inline-flex',
        mb: '40px',
        width: '100%',
        maxWidth: tabsWidth,
        '.MuiTabs-flexContainer': {
          position: 'relative',
          zIndex: 1,
          border: `1px solid ${COLORS.GREY_LIGHT_1}`,
          borderRadius: '12px',
          padding: '4px',
          '.MuiTab-root': {
            textTransform: 'capitalize',
            height: '40px',
            minHeight: 'unset',
            px: '28px',
            fontSize: '16px',
            color: COLORS.GREY_MEDIUM_1,
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,

            '&.Mui-selected': {
              color: 'white',
            },
          },
        },

        '.MuiTabs-indicator': {
          backgroundColor: COLORS.BLACK_DARK_1,
          height: '40px',
          borderRadius: '12px',
          minHeight: 'unset',
          top: '0',
          bottom: '0',
          margin: 'auto',
        },
        ...tabListSx,
      }}
      onChange={onChange}
      data-cy="ride-type-tabs"
    >
      {tabs.map(({ label, value: val, dataCy }) => (
        <Tab
          key={val}
          label={label}
          value={val}
          sx={{
            whiteSpace: 'noWrap',
          }}
          data-cy={dataCy}
        />
      ))}
    </TabList>

    {content.map((c, index) => (
      <TabPanel
        key={tabs[index].value}
        value={tabs[index].value}
        sx={{
          padding: 0,
          width: '100%',
        }}
      >
        {c}
      </TabPanel>
    ))}
  </TabContext>
);

export default TabSystem;
