import { Box } from '@mui/material';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import BookRideHeader from '../../bookRideHeader';
import { Controller, useFormContext } from 'react-hook-form';
import TabSystem from '../../../../components/shared/TabSystem';
import { BookRideFieldNames, TripTypes } from '../../types';
import OneWayForm from './OneWayForm';

import RoundtripForm from './RoundtripForm';
import HourlyForm from './HourlyForm';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const BookRideStep1 = (): ReactNode => {
  const { control, watch, setValue, clearErrors } = useFormContext() ?? {};
  const [tabs, setTabs] = useState<
    { label: string; value: string; content: ReactNode }[]
  >([]);
  const { programPass } = useProgramPassContext();

  const tripType = watch('TRIP_TYPE');

  const createTabs = useCallback(() => {
    const allowedOneWay =
      !programPass ||
      programPass.businessProgram.allowedTripTypes.includes(TripTypes.OneWay);
    const allowedRoundTrip =
      !programPass ||
      programPass.businessProgram.allowedTripTypes.includes(
        TripTypes.RoundTrip,
      );
    const allowedHourly =
      !programPass ||
      programPass.businessProgram.allowedTripTypes.includes(TripTypes.Hourly);

    const localTabs: {
      label: string;
      value: string;
      content: ReactNode;
      dataCy: string;
    }[] = [];
    if (allowedOneWay) {
      localTabs.push({
        label: 'One-way',
        value: '0',
        content: <OneWayForm key="tab 1" isGettingServiceProvider={false} />,
        dataCy: 'one-way-tab',
      });
    }
    if (allowedRoundTrip) {
      localTabs.push({
        label: 'Roundtrip',
        value: '1',
        content: <RoundtripForm isGettingServiceProvider={false} key="tab 2" />,
        dataCy: 'roundtrip-tab',
      });
    }
    if (allowedHourly) {
      localTabs.push({
        label: 'Hourly',
        value: '2',
        content: <HourlyForm key="tab 3" isGettingServiceProvider={false} />,
        dataCy: 'hourly-tab',
      });
    }

    setTabs(localTabs);

    if (!localTabs.find((t) => parseInt(t.value) === tripType))
      setValue('TRIP_TYPE', parseInt(localTabs[0].value));
  }, [programPass, setValue, tripType]);

  useEffect(() => {
    createTabs();
  }, [createTabs]);

  return (
    <>
      <Box
        sx={{
          mb: '40px',
        }}
      >
        <BookRideHeader
          isPass={Boolean(programPass)}
          imageUrl={programPass?.businessProgram.heroImageUrl}
          title={programPass?.businessProgram.cmsTitle}
          description={programPass?.businessProgram.cmsSubtitle}
          informationMessage={
            programPass?.businessProgram.cmsInformationMessage
          }
        />
        <Box
          sx={{
            display: 'inline-block',
            mt: '27px',
            width: '100%',
          }}
        >
          <Controller
            name={BookRideFieldNames.TRIP_TYPE}
            control={control}
            render={({ field: { value, onChange } }): ReactElement => (
              <TabSystem
                value={value.toString() ?? ''}
                tabs={tabs}
                content={tabs.map((tab) => tab.content)}
                onChange={(_, option): void => {
                  clearErrors();
                  onChange(parseInt(option));
                }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default BookRideStep1;
