import CardStepItem from './CardStepItem';
import React, { FC, useCallback, useEffect, useState } from 'react';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';
import {
  BookRideFieldNames,
  BusinessTravelProgram,
  ProgramPaymentMethods,
} from '../../types';
import { MenuItem, TextField, Typography } from '@mui/material';
import {
  ServiceTypes,
  useBaseService,
} from '../../../../services/useBaseService';
import { ENDPOINTS } from '../../../../services/Endpoints';
import { useFormContext } from 'react-hook-form';
import { LoadingComponent } from 'components/shared/LoadingComponent';
import { COLORS } from '../../../../theme/pallete';
import { TYPOGRAPHY } from '../../../../theme/typography';
import { LOCAL_STORAGE_KEYS } from 'shared.config';
import { useAuthContext } from 'auth/useAuthContext';

interface Props {
  number: number;
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton: boolean;
}
const BusinessTravel: FC<Props> = ({
  number,
  onContinue,
  onEdit,
  isEditing,
  displayEditButton,
}) => {
  const { watch, setValue, trigger } = useFormContext();
  const [businessTravelAccount, businessTravelProgram] = watch([
    BookRideFieldNames.BUSINESS_TRAVEL_ACCOUNT,
    BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM,
  ]);
  const { get } = useBaseService({ type: ServiceTypes.AccountAPI });
  const { user } = useAuthContext();
  const [businessTravelPrograms, setBusinessTravelPrograms] =
    useState<BusinessTravelProgram[]>();
  const [lastBusinessAccountId, setLastBusinessAccountId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setProgramIdAsSelected = useCallback((programId: string): void => {
    const selectedBusinessTravelProgram = businessTravelPrograms?.find(
      (p) => p.id === programId,
    );

    setValue(
      BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM,
      selectedBusinessTravelProgram,
    );

    if (selectedBusinessTravelProgram?.id)
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LAST_PROGRAM_ID_USED,
        selectedBusinessTravelProgram.id,
      );
    else localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_PROGRAM_ID_USED);

    if (
      (selectedBusinessTravelProgram?.directlyBilledOrganization ||
        selectedBusinessTravelProgram?.paidByInvoice) &&
      !selectedBusinessTravelProgram?.passengerPays
    ) {
      setValue(
        BookRideFieldNames.PROGRAM_PAYMENT_METHOD,
        ProgramPaymentMethods.DirectlyBilled,
      );
    } else {
      setValue(BookRideFieldNames.PROGRAM_PAYMENT_METHOD, undefined);
    }

    setValue('PAYMENT', undefined);
  }, [businessTravelPrograms, setValue]);

  const getBusinessTravelProgramsFromAccount = useCallback(async () => {
    setIsLoading(true);
    try {
      const programs: BusinessTravelProgram[] = await get(
        ENDPOINTS.GetAllBusinessTravelProgramsFromAccount(
          businessTravelAccount.id,
        ),
        true,
      );

      setBusinessTravelPrograms(programs);
      const lastProgramId = localStorage.getItem(
        LOCAL_STORAGE_KEYS.LAST_PROGRAM_ID_USED,
      );
      const lastLoggedInUser = localStorage.getItem(
        LOCAL_STORAGE_KEYS.LAST_LOGGED_IN_USER_ID,
      );
      if (lastProgramId && lastLoggedInUser === user?.user_id) {
        const lastProgram = programs.find((p) => p.id === lastProgramId);
        if (lastProgram)
          setValue(BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM, lastProgram);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [get, businessTravelAccount.id, user?.user_id, setValue]);

  const continueNextSection = useCallback(async (): Promise<void> => {
    const valid = await trigger(
      BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM
    );
    if (valid) onContinue();
  }, [onContinue, trigger]);

  useEffect(() => {
    if (
      businessTravelAccount &&
      businessTravelAccount.id !== lastBusinessAccountId
    ) {
      setValue(BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM, undefined);
      setLastBusinessAccountId(businessTravelAccount.id);
      getBusinessTravelProgramsFromAccount();
    }
  }, [
    getBusinessTravelProgramsFromAccount,
    businessTravelAccount,
    lastBusinessAccountId,
    setValue,
  ]);

  useEffect(() => {
    if (
      businessTravelPrograms &&
      businessTravelPrograms.length === 1 &&
      businessTravelProgram?.id !== businessTravelPrograms[0].id &&
      businessTravelPrograms[0].id
    ) {
      setProgramIdAsSelected(businessTravelPrograms[0].id);
      continueNextSection();
    } else if (
      (
        businessTravelProgram?.id && ((
        businessTravelPrograms &&
        businessTravelPrograms.length === 0) || ( 
          businessTravelPrograms?.filter((p) => p.id === businessTravelProgram?.id).length === 0 
        )
    ))) {
      setProgramIdAsSelected('');
    }
  }, [businessTravelProgram?.id, businessTravelPrograms, continueNextSection, setProgramIdAsSelected]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!isLoading && businessTravelPrograms) {
    return (
      <CardStepItem
        title={`${number}. Business travel program`}
        onContinue={continueNextSection}
        onEdit={onEdit}
        isEditing={isEditing}
        displayEditButton={displayEditButton}
        containerDataCy="business-travel-program-card"
      >
        {!isEditing && <Typography>{businessTravelProgram?.name}</Typography>}
        {isEditing && (
          <FormFieldLayout
            name={BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM}
            label="Select your business travel program"
            dataCy="business-travel-program-select"
          >
            <TextField
              select
              value={
                businessTravelPrograms.find(
                  (p) => p.id === businessTravelProgram?.id,
                )
                  ? businessTravelProgram?.id
                  : ''
              }
              onChange={(event): void => setProgramIdAsSelected(event.target.value)}
              sx={{
                backgroundColor: COLORS.GREY_LIGHT_4,
                fieldset: {
                  border: 'none',
                },
                ...TYPOGRAPHY.formField,
              }}
            >
              {businessTravelPrograms?.map((program) => {
                return (
                  <MenuItem key={program.id} value={program.id}>
                    {program.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormFieldLayout>
        )}
      </CardStepItem>
    );
  }
};

export default BusinessTravel;
