import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';
import { BookRideFieldNames, FlightTypes } from '../../types';
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import CardStepItem from './CardStepItem';
import TabSystem from '../../../../components/shared/TabSystem';
import { Controller, useFormContext } from 'react-hook-form';
import RHFAutocomplete from '../../../../components/shared/formFieldRelated/RHFAutocomplete';
import RHFTextField from '../../../../components/shared/formFieldRelated/RHFTextField';
import { COLORS } from '../../../../theme/pallete';
import SvgIcon from '../../../../components/shared/SvgIcon';
import {
  ServiceTypes,
  useBaseService,
} from '../../../../services/useBaseService';
import { ENDPOINTS } from '../../../../services/Endpoints';

interface Props {
  number: number;
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton: boolean;
}

type AirlinesResponse = {
  name: string;
  fs: string;
  iata: string;
  icao: string;
  mongoDbId: null;
  id: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: null;
}[];

const airlineFields = [
  BookRideFieldNames.FLIGHT_TYPE,
  BookRideFieldNames.AIRLINE,
  BookRideFieldNames.ARRIVAL_FLIGHT,
  BookRideFieldNames.FLIGHT_TAIL_NUMBER,
];

const FlightInfo: FC<Props> = ({
  onContinue,
  onEdit,
  number,
  isEditing = true,
  displayEditButton,
}): ReactElement => {
  const { control, watch, trigger } = useFormContext();
  const [airlines, setAirlines] = useState<AirlinesResponse>([]);

  const [flightType, airline, arrivalFlight, flightTailNumber] =
    watch(airlineFields);

  const { get } = useBaseService({ type: ServiceTypes.RideAPI });

  const getAirlines = useCallback(async () => {
    try {
      const gottenAirlines: AirlinesResponse = await get(
        ENDPOINTS.GetAirlines,
        false,
      );

      setAirlines(gottenAirlines);
    } catch (error) {
      console.error('Error getting airlines', error);
    }
  }, [get]);

  useEffect(() => {
    if (airlines.length === 0) {
      getAirlines();
    }
  }, [airlines, getAirlines]);

  const validateAndContinue = async (): Promise<void> => {
    const fieldsValid = await trigger(airlineFields);
    if (fieldsValid) {
      onContinue();
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Box>
      <CardStepItem
        title={`${number}. Your flight`}
        onContinue={async (): Promise<void> => await validateAndContinue()}
        onEdit={onEdit}
        isEditing={isEditing}
        displayEditButton={displayEditButton}
        containerDataCy="flight-card"
        titleDataCy="flight-card-text"
        buttonDataCy="flight-button"
        editButtonDataCy="flight-details-edit-btn"
      >
        {!isEditing && (
          <>
            <Typography
              sx={{
                ...TYPOGRAPHY.paragraph,
                mt: '5px!important',
              }}
            >
              <>
                {flightType === FlightTypes.Commercial &&
                  `${airline?.label ?? ''} ${arrivalFlight ?? ''}`}

                {flightType === FlightTypes.Private && flightTailNumber}
              </>
            </Typography>
          </>
        )}
        {isEditing && (
          <>
            <Box
              sx={{
                backgroundColor: COLORS.PURPLE_LIGHT_1,
                borderRadius: '10px',
                padding: '15px 25px',
              }}
            >
              <Typography
                sx={{
                  ...TYPOGRAPHY.tinyText,
                }}
                data-cy="flight advice"
              >
                We will adjust your pickup time to your actual landing time plus
                the additional time you have selected so that we can pick you up
                on time even if your flight is delayed.
              </Typography>
            </Box>

            <Controller
              name={BookRideFieldNames.FLIGHT_TYPE}
              control={control}
              render={({ field: { value, onChange } }): ReactElement => (
                <TabSystem
                  value={value.toString()}
                  tabs={[
                    {
                      label: 'Commercial',
                      value: FlightTypes.Commercial.toString(),
                      dataCy: 'comercial-flight',
                    },
                    {
                      label: 'Private',
                      value: FlightTypes.Private.toString(),
                      dataCy: 'private-flight',
                    },
                  ]}
                  content={[
                    <Stack key="tab 1" spacing="20px">
                      <FormFieldLayout
                        name={BookRideFieldNames.AIRLINE}
                        label="Airline"
                        dataCy="airline-selector"
                      >
                        <RHFAutocomplete
                          name={BookRideFieldNames.AIRLINE}
                          options={airlines.map((airline) => {
                            return {
                              label: airline.name,
                              value: airline.id,
                            };
                          })}
                          isOptionEqualToValue={(option, value): boolean => {
                            return option.value === value.value;
                          }}
                          sx={{
                            fieldset: {
                              border: 'none',
                            },
                            '.MuiInputBase-root': {
                              height: '56px',
                              background: COLORS.GREY_LIGHT_4,
                            },
                          }}
                          leadingIcon={
                            <SvgIcon
                              src="/assets/icons/icon_magnifier.svg"
                              width="20px"
                              color="black"
                            />
                          }
                          placeholder="e.g. Jetblue"
                        />
                      </FormFieldLayout>
                      <FormFieldLayout
                        name={BookRideFieldNames.ARRIVAL_FLIGHT}
                        label="Arrival flight"
                        dataCy="flight-number-input"
                      >
                        <RHFTextField
                          name={BookRideFieldNames.ARRIVAL_FLIGHT}
                          placeholder="e.g. 789"
                          type="number"
                          sx={{
                            ...TYPOGRAPHY.formField,
                          }}
                          className="removeArrowsFromInputTypeNumber"
                          onKeyDown={handleKeyDown}
                        />
                      </FormFieldLayout>
                    </Stack>,
                    <Box key="tab 2">
                      <FormFieldLayout
                        name={BookRideFieldNames.FLIGHT_TAIL_NUMBER}
                        label="Flight tail number"
                      >
                        <RHFTextField
                          name={BookRideFieldNames.FLIGHT_TAIL_NUMBER}
                          placeholder="e.g. N117UW"
                        />
                      </FormFieldLayout>
                    </Box>,
                  ]}
                  onChange={(_, option): void => onChange(parseInt(option))}
                  tabsWidth="100%"
                />
              )}
            ></Controller>
          </>
        )}
      </CardStepItem>
    </Box>
  );
};

export default FlightInfo;
