import { Box, Grid, Stack, Typography } from '@mui/material';
import { FONT_FAMILY, TYPOGRAPHY } from '../../../../theme/typography';
import React, { ReactElement, useId } from 'react';
import useModal from '../../../../HookComponents/useModal';
import { COLORS } from '../../../../theme/pallete';
import AppButton from '../../../../components/shared/AppButton';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames, SurchargeListType } from '../../types';
import { formatToUSD } from '../../../../utils/MoneyConvertion';

const Total = ({
  showPrices = true,
}: {
  showPrices: boolean;
}): ReactElement => {
  const { Modal, open, openModal, closeModal } = useModal();
  const id = useId();

  const { watch } = useFormContext();

  const [vehicleClass] = watch([BookRideFieldNames.SERVICE_PROVIDER_VEHICLE_CLASS]);
  const totalText = showPrices
    ? `${formatToUSD(vehicleClass?.totalGeneral)}`
    : '* Included in package';

  return (
    <Box
      sx={{
        p: '20px',
      }}
      data-cy="total-card"
    >
      {showPrices && (
        <Modal
          isOpen={open}
          onClose={closeModal}
          title="Total breakdown
  "
          dialogStyles={{
            maxWidth: '444px',
            margin: '0 auto',
          }}
          dataCy="total-breakdown"
        >
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
            }}
          >
            Ride
          </Typography>
          <Grid container rowGap="10px">
            <Grid item xs={6}>
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                  color: COLORS.GREY_MEDIUM_1,
                }}
              >
                {vehicleClass?.name}
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                  color: COLORS.GREY_MEDIUM_1,
                }}
              >
                {formatToUSD(vehicleClass?.subTotal)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  ...TYPOGRAPHY.paragraph,
                }}
              >
                Taxes and fees
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                  color: COLORS.GREY_MEDIUM_1,
                }}
              >
                Taxes
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                  color: COLORS.GREY_MEDIUM_1,
                }}
              >
                {formatToUSD(vehicleClass?.tax)}
              </Typography>
            </Grid>

            {vehicleClass?.surchargesList.map(
              (vC: SurchargeListType): ReactElement => {
                return (
                  <Grid container key={id}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          ...TYPOGRAPHY.subtitle2,
                          color: COLORS.GREY_MEDIUM_1,
                        }}
                      >
                        {vC.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end">
                      <Typography
                        sx={{
                          ...TYPOGRAPHY.subtitle2,
                          color: COLORS.GREY_MEDIUM_1,
                        }}
                      >
                        {formatToUSD(vC.amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              },
            )}

            <Grid item xs={6}>
              <Typography
                sx={{
                  ...TYPOGRAPHY.paragraph,
                }}
                data-cy='data-cy="total'
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  ...TYPOGRAPHY.paragraph,
                }}
                data-cy="total-amount"
              >
                {formatToUSD(vehicleClass?.totalGeneral)}
              </Typography>
            </Grid>
          </Grid>
          <AppButton
            title="Close"
            variant="outlined"
            additionalSx={{
              mt: '20px',
            }}
            onClick={closeModal}
            data-cy="close-button"
          />
        </Modal>
      )}

      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            ...TYPOGRAPHY.title4,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          Total
          {showPrices && (
            <Box
              sx={{
                width: '15px',
                height: '15px',
                backgroundColor: '#464A4F',
                color: 'white',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                ml: '10px',
                cursor: 'pointer',
              }}
              onClick={openModal}
              data-cy="total-info-btn"
            >
              i
            </Box>
          )}
        </Box>

        <Typography
          sx={{
            ...TYPOGRAPHY.title4,
          }}
          data-cy="total-cost"
        >
          {totalText}
        </Typography>
      </Stack>

      <Typography
        sx={{
          ...TYPOGRAPHY.tinyText,
          mt: '20px',
        }}
      >
        By booking your reservation, you agree to Kaptyn&apos;s Terms of Use and
        consent to its Privacy Policy.
      </Typography>
    </Box>
  );
};

export default Total;
