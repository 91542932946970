import React from 'react';
import { Navigate } from 'react-router-dom';
import ConfirmEmail from 'pages/ConfirmEmailPage';
import SignUpPage from 'pages/SignUpPage';
import RiderInvitationAccept from 'pages/riderInvitations/RiderInvitationAccept';
import RiderInvitationInfo from 'pages/riderInvitations/RiderInvitationInfo';
import BookRidePage from 'pages/bookRide/bookRidePage';
import { ReactElement } from 'react';
import { useRoutes } from 'react-router';
import BookRideStep1 from './pages/bookRide/steps/step1/bookRide-step1';
import VehicleClassStep2 from './pages/bookRide/steps/step2/VehicleClass-step2';
import CheckoutStep3 from './pages/bookRide/steps/step3/Checkout-step3';
import BookedRideConfirmationPage from './pages/bookRide/bookedRideConfirmationPage';
import MyTripsPage from './pages/myTrips/MyTripsPage';
import MyAccount from './pages/MyAccount';
import LoginRedirect from 'pages/LoginRedirect';
import NotFound404Page from './pages/NotFound404Page';
import WrapperParamsFromUrl from './pages/bookRide/steps/step2/WrapperParamsFromUrl';

export default function Router(): ReactElement<
  unknown,
  string | React.JSXElementConstructor<unknown>
> | null {
  return useRoutes([
    {
      path: '/',
      element: (
        <>
          <Navigate to="/book-ride/step1" replace />
        </>
      ),
    },
    {
      path: '/book-ride/',
      element: <BookRidePage />,
      children: [
        {
          path: 'step1',
          element: <BookRideStep1 />,
        },
        {
          path: 'step2',
          element: (
            <WrapperParamsFromUrl>
              <VehicleClassStep2 />
            </WrapperParamsFromUrl>
          ),
        },
        {
          path: 'step3',
          element: <CheckoutStep3 />,
        },
        {
          path: 'confirmation',
          element: <BookedRideConfirmationPage />,
        },
      ],
    },
    {
      path: '/signup',
      element: <SignUpPage />,
    },
    {
      path: '/my-trips',
      element: <MyTripsPage />,
    },
    {
      path: '/my-account',
      element: <MyAccount />,
    },
    {
      path: '/login_redirect',
      element: <LoginRedirect />,
    },
    {
      path: '/pass/:code',
      element: <BookRidePage />,
      children: [
        {
          path: 'step1',
          element: <BookRideStep1 />,
        },
        {
          path: 'step2',
          element: <VehicleClassStep2 />,
        },
        {
          path: 'step3',
          element: <CheckoutStep3 />,
        },
        {
          path: 'confirmation',
          element: <BookedRideConfirmationPage />,
        },
      ],
    },
    {
      path: '/email-validation',
      element: <ConfirmEmail />,
    },
    {
      path: '/qr',
      element: <></>,
    },
    {
      path: '/program/:programId/riders/invitation/:invitationCode',
      element: <RiderInvitationInfo />,
    },
    {
      path: '/program/:programId/riders/accept_invitation/:invitationCode',
      element: <RiderInvitationAccept />,
    },
    { path: '*', element: <NotFound404Page /> },
  ]);
}
