import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router';

import FormStateWrapper from '../FormStateWrapper';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { LoadingComponent } from 'components/shared/LoadingComponent';
import Layout from 'components/shared/Layout';
import { CheckoutSectionType } from './steps/step3/types';
import { initialSectionValues } from './steps/step3/sectionHelpers';
import {
  UserType,
  VehicleClassesPoolType,
  VehicleClassesToQuotePoolType,
} from 'shared.types';
import {
  BookRideFormFields,
  FlightTypes,
  ServiceProviderVehicleClassType,
  TripTypes,
} from './types';
import { manageTime } from './utils';
import dayjs from 'dayjs';

export type RouterContextType = {
  checkoutSections: CheckoutSectionType[];
  setCheckoutSections: React.Dispatch<
    React.SetStateAction<CheckoutSectionType[]>
  >;
  lastGooglePlacesIdPickup: string | undefined;
  setLastGooglePlacesIdPickup: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  serviceProviderId: string | undefined | null;
  setServiceProviderId: React.Dispatch<string | undefined | null>;
  vehicleClassesToQuote: ServiceProviderVehicleClassType[] | undefined;
  setVehicleClassesToQuote: React.Dispatch<
    React.SetStateAction<ServiceProviderVehicleClassType[] | undefined>
  >;
  vehicleClassesToQuotePool: VehicleClassesToQuotePoolType[] | undefined; // Add this line
  setVehicleClassesToQuotePool: React.Dispatch<
    React.SetStateAction<VehicleClassesToQuotePoolType[] | undefined>
  >;
  vehicleClassesPool: VehicleClassesPoolType[] | undefined; // Add this line
  setVehicleClassesPool: React.Dispatch<
    React.SetStateAction<VehicleClassesPoolType[] | undefined>
  >;
};

const defaultFormValues = (user: UserType | null): BookRideFormFields => ({
  TRIP_TYPE: TripTypes.OneWay,
  PICKUP_DATE: dayjs().format('YYYY-MM-DD'),
  PICKUP_TIME: manageTime({
    dayJsObj: dayjs(),
    hoursToAdd: 2,
  }),
  RETURN_PICKUP_DATE: dayjs().format('YYYY-MM-DD'),

  RETURN_PICKUP_TIME: 'none',
  ESTIMATED_TIME_IN_HOURS: '1',
  PASSENGERS_AMOUNT: '1',
  LUGGAGE_AMOUNT: '1',
  FLIGHT_TYPE: FlightTypes.Commercial,
  FIRST_NAME: user?.first_name ?? '',
  LAST_NAME: user?.last_name ?? '',
  EMAIL: user?.email ?? '',
  PHONENUMBER: user?.phone_number ?? '',
  SPECIAL_REQUESTS: null,
});

const BookRidePage = ({
  formInitialValues = defaultFormValues,
}: {
  formInitialValues?: (user: UserType | null) => BookRideFormFields;
}): ReactElement => {
  const [checkoutSections, setCheckoutSections] =
    useState<CheckoutSectionType[]>(initialSectionValues);
  const [lastGooglePlacesIdPickup, setLastGooglePlacesIdPickup] =
    useState<string>();
  const [serviceProviderId, setServiceProviderId] = useState<
    string | undefined | null
  >();
  const [vehicleClassesToQuote, setVehicleClassesToQuote] =
    useState<ServiceProviderVehicleClassType[]>();
  const [vehicleClassesToQuotePool, setVehicleClassesToQuotePool] =
    useState<VehicleClassesToQuotePoolType[]>();
  const [vehicleClassesPool, setVehicleClassesPool] =
    useState<VehicleClassesPoolType[]>();

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
      libraries={['places']}
      render={(status): ReactElement => {
        if (status === Status.LOADING) return <LoadingComponent />;
        if (status === Status.FAILURE) return <h3>{status} ...</h3>;
        return (
          <FormStateWrapper
            setCheckoutSections={setCheckoutSections}
            formInitialValues={formInitialValues}
          >
            <Layout>
              <Outlet
                context={
                  {
                    checkoutSections,
                    setCheckoutSections,
                    lastGooglePlacesIdPickup,
                    setLastGooglePlacesIdPickup,
                    serviceProviderId,
                    setServiceProviderId,
                    vehicleClassesToQuote,
                    setVehicleClassesToQuote,
                    vehicleClassesToQuotePool,
                    setVehicleClassesToQuotePool,
                    vehicleClassesPool,
                    setVehicleClassesPool,
                  } satisfies RouterContextType
                }
              />
            </Layout>
          </FormStateWrapper>
        );
      }}
    />
  );
};

export default BookRidePage;
