import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { FONT_FAMILY } from '../../../theme/typography';

interface Props {
  name: string;
}

const ErrorMessage: React.FC<Props> = ({ name }) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <>
      {errors[name] && (
        <Typography
          sx={{
            // position: 'absolute',
            bottom: '-20px',
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
            fontSize: '13px',
            color: 'red',
            mt: '5px',
          }}
          data-cy="errorMessage"
        >
          <>{errors[name]?.message as string}</>
        </Typography>
      )}
    </>
  );
};

export default ErrorMessage;
