import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { BookRideFieldNames } from '../../types';

import { AddressTypes } from '../../../../shared.types';
import { useFormContext } from 'react-hook-form';

import { useSearchParams } from 'react-router-dom';
import { getLocationFromGooglePlacesId } from '../../../../utils/googlePlacesUtils';
import { createPortal } from 'react-dom';
import FullScreenLoader from './FullScreenLoader';
import { useGetFieldsByCurrTripType } from './utils';

type SearchParamsType = {
  rideType?: string;
  fromAddressPlaceId?: string;
  fromAddressDescription?: string;
  toAddressPlaceId?: string;
  toAddressDescription?: string;
  pickUpDate?: string;
  pickUpTime?: string;
  returnPickUpDate?: string;
  returnPickUpTime?: string;
  duration?: string;
};

const readUrlSearchParams = (
  searchParams: URLSearchParams,
): SearchParamsType => {
  const fromAddressDescription = searchParams.get('fromAddressDescription');
  const toAddressDescription = searchParams.get('toAddressDescription');

  return {
    rideType: searchParams.get('rideType') || undefined,
    fromAddressPlaceId: searchParams.get('fromAddressPlaceId') || undefined,
    fromAddressDescription: fromAddressDescription
      ? decodeURIComponent(fromAddressDescription)
      : undefined,
    toAddressPlaceId: searchParams.get('toAddressPlaceId') || undefined,
    toAddressDescription: toAddressDescription
      ? decodeURIComponent(toAddressDescription)
      : undefined,
    pickUpDate: searchParams.get('pickUpDate') || undefined,
    pickUpTime: searchParams.get('pickUpTime') || undefined,
    returnPickUpDate: searchParams.get('returnPickUpDate') || undefined,
    returnPickUpTime: searchParams.get('returnPickUpTime') || undefined,
    duration: searchParams.get('duration') || undefined,
  } as SearchParamsType;
};

const WrapperParamsFromUrl: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getFieldsByCurrTripType = useGetFieldsByCurrTripType();

  const { setValue } = useFormContext();

  useEffect(() => {
    const paramsValue = readUrlSearchParams(searchParams);

    if (paramsValue.rideType && paramsValue.fromAddressPlaceId)
      setRideDataFromUrl();

    async function setRideDataFromUrl(): Promise<void> {
      setValue(
        BookRideFieldNames.TRIP_TYPE,
        parseInt(paramsValue.rideType as string),
      );

      if (
        paramsValue.fromAddressPlaceId &&
        paramsValue.fromAddressDescription
      ) {
        const pickupLocation = await getLocationFromGooglePlacesId(
          paramsValue.fromAddressPlaceId,
          paramsValue.fromAddressDescription,
          AddressTypes.PickUp,
        );
        setValue(BookRideFieldNames.PICKUP_ADDRESS, pickupLocation);
      }

      if (paramsValue.toAddressPlaceId && paramsValue.toAddressDescription) {
        const destLocation = await getLocationFromGooglePlacesId(
          paramsValue.toAddressPlaceId,
          paramsValue.toAddressDescription,
          AddressTypes.DropOff,
        );
        setValue(BookRideFieldNames.DROPOFF_ADDRESS, destLocation);
      }

      setValue(BookRideFieldNames.PICKUP_DATE, paramsValue.pickUpDate);
      setValue(BookRideFieldNames.PICKUP_TIME, paramsValue.pickUpTime);

      if (paramsValue.returnPickUpDate)
        setValue(
          BookRideFieldNames.RETURN_PICKUP_DATE,
          paramsValue.returnPickUpDate,
        );
      if (paramsValue.returnPickUpTime)
        setValue(
          BookRideFieldNames.RETURN_PICKUP_TIME,
          paramsValue.returnPickUpTime,
        );
      if (paramsValue.duration)
        setValue(
          BookRideFieldNames.ESTIMATED_TIME_IN_HOURS,
          paramsValue.duration,
        );
    }
  }, [searchParams, setSearchParams, setValue]);

  const isStepOneValid = useMemo(() => {
    const returned = getFieldsByCurrTripType();
    const isFormDataValid = returned
      ? Object.values(returned).every((value) => value !== undefined)
      : false;

    return isFormDataValid;
  }, [getFieldsByCurrTripType]);

  useEffect(() => {
    if (isStepOneValid) {
      const { pathname, hash } = window.location;

      // Combine the path and hash to form the new URL
      const newUrl = pathname + hash;

      // Use history.replaceState() to change the URL without query parameters
      // and without adding a new entry in the browser's history stack
      window.history.replaceState({}, '', newUrl);
    }
    // setSearchParams();
  }, [searchParams, setSearchParams, isStepOneValid]);

  if (searchParams.size !== 0) {
    if (!isStepOneValid) {
      return <>{createPortal(<FullScreenLoader />, document.body)}</>;
    }
  }

  return <>{children}</>;
};

export default WrapperParamsFromUrl;
