import * as yup from 'yup';
import { ServiceTypes, useBaseService } from '../../services/useBaseService';
import { AppFormErrorMgs } from '../../constans/ErrorMessages';
import { LocationDetailsType } from '../../shared.types';
import { ENDPOINTS } from '../../services/Endpoints';
import {
  BookRideFieldNames,
  BookRideFormFields,
  BusinessAccountType,
  FlightTypes,
  ProgramPaymentMethods,
  TripTypes,
} from './types';
import dayjs from 'dayjs';
import {
  DATE_FORMAT,
  TIME_12HRS_FORMAT,
} from '../../constans/DateRelatedContants';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export const useBookRidePageSchema = (): {
  schema: yup.ObjectSchema<BookRideFormFields>;
} => {
  const { get } = useBaseService({ type: ServiceTypes.RideAPI });
  const [lastPickupLocTested, setLastPickupLocTested] = useState<string>();
  const [lastPickupLocResult, setLastPickupLocResult] = useState<boolean>();
  const [lastDropoffLocTested, setLastDropoffLocTested] = useState<string>();
  const [lastDropoffLocResult, setLastDropoffLocResult] = useState<boolean>();

  const location = useLocation();

  const schema: yup.ObjectSchema<BookRideFormFields> = yup.object().shape({
    TRIP_TYPE: yup.number().required(AppFormErrorMgs.required),
    PICKUP_ADDRESS: yup
      .object()
      .test('check-obj', AppFormErrorMgs.required, function (value) {
        return value && Object.keys(value).length > 0;
      })
      .when(BookRideFieldNames.TRIP_TYPE, {
        is: (value: TripTypes) =>
          value === TripTypes.OneWay || value === TripTypes.RoundTrip,
        then: (schema) =>
          schema.test(
            'Not the same',
            AppFormErrorMgs.equalToDropOff,
            function (value: Partial<LocationDetailsType>, context) {
              return !(
                value &&
                value?.googlePlacesId ===
                  context.parent.DROPOFF_ADDRESS?.googlePlacesId
              );
            },
          ),
      })
      .test(
        'out of service',
        AppFormErrorMgs.locationOutOfService,
        async (value: Partial<LocationDetailsType>) => {
          if (!value?.googlePlacesId) return false;
          let finalResponse = lastPickupLocResult === true;

          if (value?.googlePlacesId !== lastPickupLocTested) {
            finalResponse = true;
            setLastPickupLocTested(value.googlePlacesId as string);
            if (value?.latitude && value?.longitude) {
              try {
                await get(
                  `${ENDPOINTS.GetFromLocation}?lat=${value?.latitude}&lng=${value?.longitude}`,
                  false,
                  false,
                  true,
                );
              } catch (e) {
                finalResponse = false;
              }
            }
            setLastPickupLocResult(finalResponse);
          }

          return finalResponse || false;
        },
      ),
    DROPOFF_ADDRESS: yup.object().when(BookRideFieldNames.TRIP_TYPE, {
      is: (value: TripTypes) =>
        value === TripTypes.OneWay || value === TripTypes.RoundTrip,
      then: (schema) =>
        schema
          .test('check-obj', AppFormErrorMgs.required, function (value) {
            return value && Object.keys(value).length > 0;
          })
          .test(
            'Not the same',
            AppFormErrorMgs.equalToPickup,
            function (value: Partial<LocationDetailsType>, context) {
              return !(
                value &&
                value?.googlePlacesId ===
                  context.parent.PICKUP_ADDRESS?.googlePlacesId
              );
            },
          )
          .test(
            'out of service',
            AppFormErrorMgs.locationOutOfService,
            async (value: Partial<LocationDetailsType>) => {
              if (!value?.googlePlacesId) return false;

              let finalResponse = lastDropoffLocResult === true;

              if (value?.googlePlacesId !== lastDropoffLocTested) {
                finalResponse = true;
                setLastDropoffLocTested(value.googlePlacesId as string);
                if (value?.latitude && value?.longitude) {
                  try {
                    await get(
                      `${ENDPOINTS.GetFromLocation}?lat=${value?.latitude}&lng=${value?.longitude}`,
                      false,
                      false,
                      true,
                    );
                  } catch (e) {
                    finalResponse = false;
                  }
                }

                setLastDropoffLocResult(finalResponse);
              }

              return finalResponse || false;
            },
          ),
    }),
    PICKUP_DATE: yup.string().required(AppFormErrorMgs.required),
    PICKUP_TIME: yup
      .string()
      .required(AppFormErrorMgs.required)
      .test(
        'Beyond 2 hours',
        AppFormErrorMgs.timeInFuture(
          dayjs().add(2, 'hour').format(`${TIME_12HRS_FORMAT} ${DATE_FORMAT} `),
        ),
        (value, context) => {
          if (!location.pathname.includes('step1')) {
            return true;
          }
          return (
            location.pathname.includes('step1') &&
            dayjs()
              .add(2, 'hour')
              .isBefore(
                dayjs(
                  `${context.parent.PICKUP_DATE} ${context.parent.PICKUP_TIME}`,
                ),
              )
          );
        },
      ),
    RETURN_PICKUP_DATE: yup
      .string()
      .nullable()
      .when(BookRideFieldNames.TRIP_TYPE, {
        is: TripTypes.RoundTrip,
        then: (schema) => schema.required(AppFormErrorMgs.required),
      }),
    RETURN_PICKUP_TIME: yup
      .string()
      .nullable()
      .when(BookRideFieldNames.TRIP_TYPE, {
        is: TripTypes.RoundTrip,
        then: (schema) =>
          schema
            .required(AppFormErrorMgs.required)
            .test('not-default', AppFormErrorMgs.required, function (value) {
              return value !== 'none';
            })
            .test(
              'not before',
              AppFormErrorMgs.returnPickTimeAfter,
              (value, context) => {
                return !dayjs(
                  `${context.parent.PICKUP_DATE} ${context.parent.PICKUP_TIME}`,
                ).isAfter(
                  dayjs(`${context.parent.RETURN_PICKUP_DATE} ${value}`),
                );
              },
            ),
      }),
    ESTIMATED_TIME_IN_HOURS: yup
      .string()
      .nullable()
      .when(BookRideFieldNames.TRIP_TYPE, {
        is: TripTypes.Hourly,
        then: (schema) => schema.required(AppFormErrorMgs.required),
      }),
    PASSENGERS_AMOUNT: yup.string().required(AppFormErrorMgs.required),
    LUGGAGE_AMOUNT: yup.string().required(AppFormErrorMgs.required),
    SERVICE_PROVIDER_VEHICLE_CLASS: yup
      .object()
      .required(AppFormErrorMgs.required),
    FIRST_NAME: yup.string().required(AppFormErrorMgs.required),
    LAST_NAME: yup.string().required(AppFormErrorMgs.required),
    EMAIL: yup.string().required(AppFormErrorMgs.required),
    PHONENUMBER: yup
      .string()
      .required(AppFormErrorMgs.required)
      .test(
        'test agaist E164 standard',
        AppFormErrorMgs.invalidPhoneNumber,
        (phonenumber) =>
          // It tests against the E164 phone number international standard
          /^\+[1-9]\d{10,14}$/.test(phonenumber),
      ),
    FLIGHT_TYPE: yup.mixed<FlightTypes>().required(AppFormErrorMgs.required),

    AIRLINE: yup
      .object()
      .when(
        [
          'FLIGHT_TYPE',
          'PICKUP_ADDRESS',
          BookRideFieldNames.DROPOFF_ADDRESS,
          BookRideFieldNames.TRIP_TYPE,
        ],
        {
          is: (
            FLIGHT_TYPE: FlightTypes,
            PICKUP_ADDRESS: Partial<LocationDetailsType>,
            DROPOFF_ADDRESS: Partial<LocationDetailsType>,
            TRIP_TYPE: TripTypes,
          ) =>
            FLIGHT_TYPE === FlightTypes.Commercial &&
            (PICKUP_ADDRESS?.airportCode ||
              (DROPOFF_ADDRESS?.airportCode &&
                TRIP_TYPE === TripTypes.RoundTrip)),
          then: (schema) => schema.required(AppFormErrorMgs.required),
        },
      ),

    ARRIVAL_FLIGHT: yup
      .string()
      .when(
        [
          'FLIGHT_TYPE',
          'PICKUP_ADDRESS',
          BookRideFieldNames.DROPOFF_ADDRESS,
          BookRideFieldNames.TRIP_TYPE,
        ],
        {
          is: (
            FLIGHT_TYPE: FlightTypes,
            PICKUP_ADDRESS: Partial<LocationDetailsType>,
            DROPOFF_ADDRESS: Partial<LocationDetailsType>,
            TRIP_TYPE: TripTypes,
          ) =>
            FLIGHT_TYPE === FlightTypes.Commercial &&
            (PICKUP_ADDRESS?.airportCode ||
              (DROPOFF_ADDRESS?.airportCode &&
                TRIP_TYPE === TripTypes.RoundTrip)),
          then: (schema) => schema.required(AppFormErrorMgs.required),
        },
      )
      .test(
        'Cannot start with zero 0',
        AppFormErrorMgs.cannotStartWithZero,
        (value) => {
          return !value?.startsWith('0');
        },
      ),
    FLIGHT_TAIL_NUMBER: yup
      .string()
      .when(
        [
          'FLIGHT_TYPE',
          'PICKUP_ADDRESS',
          BookRideFieldNames.DROPOFF_ADDRESS,
          BookRideFieldNames.TRIP_TYPE,
        ],
        {
          is: (
            FLIGHT_TYPE: FlightTypes,
            PICKUP_ADDRESS: Partial<LocationDetailsType>,
            DROPOFF_ADDRESS: Partial<LocationDetailsType>,
            TRIP_TYPE: TripTypes,
          ) =>
            FLIGHT_TYPE === FlightTypes.Private &&
            (PICKUP_ADDRESS?.airportCode ||
              (DROPOFF_ADDRESS?.airportCode &&
                TRIP_TYPE === TripTypes.RoundTrip)),
          then: (schema) => schema.required(AppFormErrorMgs.required),
        },
      ),
    SPECIAL_REQUESTS: yup.string().nullable(),
    TIP_AMOUNT: yup.number(),
    RIDER: yup.object(),
    PROGRAM_PAYMENT_METHOD: yup.number(),
    PAYMENT: yup.object().when('PROGRAM_PAYMENT_METHOD', {
      is: (value: ProgramPaymentMethods | undefined) =>
        value !== ProgramPaymentMethods.DirectlyBilled,
      then: (schema) => schema.required(AppFormErrorMgs.required),
    }),
    BUSINESS_TRAVEL_ACCOUNT: yup.object().nullable(),
    BUSINESS_TRAVEL_PROGRAM: yup.object().when('BUSINESS_TRAVEL_ACCOUNT', {
      is: (value: BusinessAccountType) => !!value,
      then: (schema) => schema.required(AppFormErrorMgs.required),
    }),
    TOTAL_PRICE: yup.number(),
    QUOTE_ID: yup.string(),
    QUOTE_ID_LEG2: yup.string(),
  });

  return {
    schema,
  };
};
