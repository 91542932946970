import { Box, darken, lighten, Stack, Typography } from '@mui/material';
import { COLORS } from '../../../../theme/pallete';
import { TYPOGRAPHY } from '../../../../theme/typography';
import SvgIcon from '../../../../components/shared/SvgIcon';
import AppButton from '../../../../components/shared/AppButton';
import React, { FC, ReactElement, useState } from 'react';
import TabSystem from '../../../../components/shared/TabSystem';
import { BookRideFieldNames, SurchargeListType, TripTypes } from '../../types';
import useModal from '../../../../HookComponents/useModal';
import { useFormContext } from 'react-hook-form';
import { formatToUSD } from '../../../../utils/MoneyConvertion';

const fillGlowingAnimation = {
  '@keyframes fill-glowing-animation': {
    '0%': {
      backgroundColor: lighten(COLORS.GREY_LIGHT_6, 0.3),
    },
    '100%': {
      backgroundColor: darken(COLORS.GREY_LIGHT_6, 0.07),
    },
  },
  animation: `fill-glowing-animation 1s both ease-in-out infinite alternate`,
};

const FareModalItem: FC<{
  total: number;
  subtotal: number;
  surchargeList: SurchargeListType[];
  tax: number;
  tip: number;
  dataCy: string;
}> = ({ total, subtotal, surchargeList, tax, tip, dataCy }): ReactElement => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          borderBottom: `1px solid ${COLORS.GREY_LIGHT_5}`,
          marginTop: '20px',
          paddingBottom: '10px',
        }}
      >
        <Typography
          sx={{
            ...TYPOGRAPHY.subtitle2,
            fontWeight: '600',
          }}
        >
          Estimated total
        </Typography>
        <Typography
          sx={{
            ...TYPOGRAPHY.subtitle2,
            fontWeight: '600',
          }}
          data-cy={dataCy}
        >
          {formatToUSD(total)}
        </Typography>
      </Stack>
      <FareEstimateModalItem label="Base fare" value={subtotal} />
      {surchargeList.map((sCL) => {
        return (
          <FareEstimateModalItem
            key={sCL.description}
            label={sCL.description}
            value={sCL.amount}
          />
        );
      })}
      <FareEstimateModalItem label="Estimated taxes" value={tax} />
      {tip > 0 && <FareEstimateModalItem label="Tip" value={tip} />}

      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
          mt: '15px',
        }}
      >
        All trips will be billed at a minimum of $60.00
      </Typography>
    </Box>
  );
};

const FareEstimateModalItem: FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        mt: '15px',
      }}
    >
      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
        }}
      >
        {formatToUSD(parseFloat(value as string))}
      </Typography>
    </Stack>
  );
};

type legsType = {
  total: number;
  subTotal: number;
  surchargeList: SurchargeListType[];
  tip: number;
  tax: number;
};

export interface VehicleClassItemType {
  title: string;
  imageUrl: string;
  subtitle: string;
  passengersAmount?: number;
  luggageAmount?: number;
  onSelectVehicle: () => void;
  services: string;
  subTotalGeneral: number;
  totalGeneral: number;
  leg1: legsType;
  leg2?: Partial<legsType>;
  showPrices: boolean;
  failedToLoad?: boolean;
  getVehicleClassOnDemand: () => void;
}

type FareTabsTypes = 'leg1' | 'leg2';

const VehicleClassItem = React.forwardRef(
  (
    {
      title,
      imageUrl,
      subtitle,
      passengersAmount,
      luggageAmount,
      onSelectVehicle,
      services,
      subTotalGeneral,
      totalGeneral,
      leg1,
      leg2,
      showPrices = true,
      failedToLoad,
      getVehicleClassOnDemand,
    }: VehicleClassItemType,

    ref,
  ): ReactElement => {
    const { Modal, open, closeModal, openModal } = useModal();
    const [currentFareModalTab, setCurrentFareModalTab] =
      useState<FareTabsTypes>('leg1');

    const { watch } = useFormContext();

    const [tripType] = watch([BookRideFieldNames.TRIP_TYPE]);

    const costSummaryLeg1 = (
      <FareModalItem
        total={leg1.total}
        surchargeList={leg1.surchargeList}
        tax={leg1.tax}
        tip={leg1.tip}
        subtotal={leg1.subTotal}
        dataCy="totalLeg1"
      />
    );

    const costSummaryLeg2 = leg2 ? (
      <FareModalItem
        total={leg2?.total ?? 0}
        surchargeList={leg2?.surchargeList ?? []}
        tax={leg2?.tax ?? 0}
        tip={leg2?.tip ?? 0}
        subtotal={leg2?.subTotal ?? 0}
        dataCy="totalLeg2"
      />
    ) : null;

    return (
      <Box
        ref={ref}
        sx={{
          border: `1px solid ${COLORS.GREY_LIGHT_5}`,
          borderRadius: '10px',
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          backgroundColor: 'white',
          flexDirection: 'column',
          opacity: 0,
          '@keyframes on-load-animation': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
          animation: `on-load-animation 0.2s ease-out both`,
        }}
        data-cy="serviceLevelListItemCard"
      >
        <Modal
          isOpen={open}
          onClose={closeModal}
          title="Fare estimate"
          dialogStyles={{
            margin: '0 auto',
            padding: '16px',

            '.MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              margin: 0,
              borderRadius: '10px',
            },
          }}
          dataCy="fareEstimateModal"
        >
          <div>
            <Typography
              sx={{
                ...TYPOGRAPHY.paragraph,
              }}
            >
              Your fare estimate reflects the expected time of your ride. Your
              final fare will be based on actual time driven.
            </Typography>

            {tripType === TripTypes.RoundTrip && (
              <TabSystem
                value={currentFareModalTab}
                tabs={[
                  {
                    label: 'Trip 1',
                    value: 'leg1',
                  },
                  {
                    label: 'Trip 2',
                    value: 'leg2',
                  },
                ]}
                content={[costSummaryLeg1, costSummaryLeg2]}
                onChange={(event, position): void => {
                  setCurrentFareModalTab(position as FareTabsTypes);
                }}
                tabListSx={{
                  mt: '20px',
                  mb: '10px',
                }}
              />
            )}

            {tripType !== TripTypes.RoundTrip && costSummaryLeg1}
          </div>
        </Modal>
        <img
          src={imageUrl}
          alt="vehicle image"
          style={{
            width: '100%',
            opacity:
              !failedToLoad && showPrices && !subTotalGeneral && !totalGeneral
                ? 0.5
                : 1,
          }}
          data-cy="serviceLevelImage"
        />
        <Box
          p="16px"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '100% 1 1',
          }}
        >
          <Stack direction="row">
            <Box mr="10px">
              <Typography
                sx={{
                  ...TYPOGRAPHY.title3,
                }}
                data-cy="serviceLevelImage"
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                }}
                data-cy="serviceLevelClass"
              >
                {subtitle}
              </Typography>
            </Box>
            <Stack ml="auto">
              <Stack direction="row" data-cy="passengerCount">
                <SvgIcon
                  src="/assets/icons/icon_user.svg"
                  color={COLORS.GREY_MEDIUM_1}
                  width="20px"
                />
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    color: COLORS.GREY_MEDIUM_1,
                    ml: '10px',
                  }}
                >
                  {passengersAmount ? passengersAmount : 'N/A'}
                </Typography>
              </Stack>
              <Stack direction="row" data-cy="luggageCount">
                <SvgIcon
                  src="/assets/icons/icon_luggage.svg"
                  color={COLORS.GREY_MEDIUM_1}
                  width="19px"
                />

                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    color: COLORS.GREY_MEDIUM_1,
                    ml: '10px',
                  }}
                >
                  {luggageAmount ? luggageAmount : 'N/A'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Box mt="25px" mb="auto">
            <div dangerouslySetInnerHTML={{ __html: services }}></div>
          </Box>

          {!failedToLoad && showPrices && !subTotalGeneral && !totalGeneral && (
            <>
              <Box
                sx={{
                  maxWidth: '60px',
                  width: '100%',
                  height: '22px',
                  borderRadius: '4px',
                  backgroundColor: COLORS.GREY_LIGHT_6,
                  ...fillGlowingAnimation,
                }}
              />

              <Box
                sx={{
                  maxWidth: '150px',
                  width: '100%',
                  height: '18px',
                  borderRadius: '4px',
                  backgroundColor: COLORS.GREY_LIGHT_6,
                  mt: '4px',
                  ...fillGlowingAnimation,
                }}
              />

              <Box
                sx={{
                  maxWidth: '374px',
                  width: '100%',
                  height: '48px',
                  borderRadius: '16px',
                  backgroundColor: COLORS.GREY_LIGHT_6,
                  mt: '16px',
                  ...fillGlowingAnimation,
                }}
              />
            </>
          )}

          {failedToLoad && (
            <>
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                }}
              >
                Couldn’t find any results..
              </Typography>
              <AppButton
                title="Search again"
                variant="outlined"
                additionalSx={{
                  mt: '10px',
                }}
                onClick={getVehicleClassOnDemand}
              />
            </>
          )}

          {showPrices && subTotalGeneral && totalGeneral && (
            <>
              <Box
                sx={{
                  mt: 'auto',
                  cursor: 'pointer',
                }}
                onClick={openModal}
                data-cy="serviceLevelQuote"
              >
                <Typography
                  sx={{
                    ...TYPOGRAPHY.title3,
                  }}
                >
                  {formatToUSD(subTotalGeneral)}
                </Typography>
                <Typography
                  sx={{
                    ...TYPOGRAPHY.subtitle2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  data-cy="totalWithTaxesAndFees"
                >
                  {formatToUSD(totalGeneral)} total with taxes & fees
                  <SvgIcon
                    src="/assets/icons/icon_arrowDown.svg"
                    width="18px"
                  />
                </Typography>
              </Box>
            </>
          )}

          {failedToLoad ||
            (!(showPrices && !subTotalGeneral && !totalGeneral) && (
              <AppButton
                title="Select"
                additionalSx={{
                  mt: '10px',
                  borderRadius: '20px',
                  ...TYPOGRAPHY.paragraph,
                  fontSize: '16px',
                  color: 'white',
                }}
                type="button"
                onClick={onSelectVehicle}
                data-cy="reserveServiceBtn"
              />
            ))}
        </Box>
      </Box>
    );
  },
);

VehicleClassItem.displayName = 'VehicleClassItem';

export default VehicleClassItem;
